.app {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.left-column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  overflow-y: auto;
  height: 100%;
}

.right-column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 7;
  overflow-y: hidden;
  height: 100%;
}
